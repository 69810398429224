import { NextSeo } from 'next-seo'
import Layout from '@/components/common/default-layout'
import Modules from '@/components/modules'
import { getPageData } from '@/lib/contentful/page'
import { addProductData } from '@/lib/contentful/page-product'
import { setPageSeo, createAlternateLinks } from '@/lib/helpers'

const PAGE_SLUG = 'homepage'

const Index = ({ preview, page }) => {

  if (typeof page.length == 'undefined') {
    const { menu, footer, modules, seo } = page

    let seoDetails = setPageSeo(page, seo,'')

    return (
      <Layout menu={menu} preview={preview} includeCart={true} footer={footer}>
        <NextSeo
          {...seoDetails}
          languageAlternates={createAlternateLinks([],'', true)}
        />
        <Modules modules={modules} />
      </Layout>
    )
  }

  return <div>Page data not found</div>
}

export default Index

export const getStaticProps = async ({ preview = false, locale }) => {
  const page = (await getPageData(PAGE_SLUG, preview)) ?? []

  const pageWithProduct = await addProductData(page, locale, preview)


  return {
    props: {
      preview,
      page: pageWithProduct
    },
  }
}